@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* base styles */
body {
  font-family: Poppins, sans-serif;
  font-size: 1.1em;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul,
li,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 10px auto;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.details-container {
  width: 70%;
  margin: 0 auto;
}

.project-card-details {
  margin: 12px 0;
}

span.add_btn {
  padding: 0 6px;
  font-size: 2rem;
  color: rgba(143, 0, 255, 1);
}

span.add_btn_right {
  padding-left: 50px;
}

.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}